import { useMemo, useRef } from 'react'

import dynamic from 'next/dynamic'

import { IconArrow, IconHeaderCart, IconNotification } from '@k_frontend/icons'
import { Badge } from '@k_frontend/ui'
import { useNotificationsStore } from 'containers/NotificationsContainer'

import AccessibilityMenuLoading from 'components/AccessibilityMenu/AccessibilityMenu.loading'
import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import { useHeaderContext } from '../Header.context'
import * as S from '../Header.styles'
import NavDrawerLoading from '../NavDrawer/NavDrawer.loading'
import Searchbar from '../Searchbar'
import * as M from './HeaderMobile.styles'

const NavDrawer = dynamic(() => import('../NavDrawer'), {
  loading: () => <NavDrawerLoading />,
  ssr: false
})

const SelectAddress = dynamic(() => import('../SelectAddress'), {
  ssr: false
})

const AccessibilityMenu = dynamic(
  () =>
    import('components/AccessibilityMenu').then(
      (module) => module.AccessibilityMenu
    ),
  {
    loading: () => <AccessibilityMenuLoading />,
    ssr: false
  }
)
function Mobile() {
  const {
    isPrime,
    isMounted,
    clean,
    cartProductsQuantity,
    isLoggedIn,
    addresses,
    MobileLogo
  } = useHeaderContext()
  const headerRef = useRef(null)
  const headerElement = headerRef.current?.getBoundingClientRect()
  const { noticeQuantity } = useNotificationsStore()
  const { isViewFloatingMenu } = useHeaderContainer()

  const renderAccessibilityMenu = useMemo(() => {
    if (!isViewFloatingMenu) {
      return <AccessibilityMenu />
    }
  }, [isViewFloatingMenu])

  function handleClick(): void {
    window.history.back()
  }

  return (
    <S.Container clean={clean}>
      <M.ContainerWrapper
        isPrime={isPrime}
        key={String(isMounted)}
        ref={headerRef}
        id='header'
      >
        {clean && (
          <>
            <M.CleanSection>
              <M.ButtonBack onClick={handleClick}>
                <IconArrow color='#fff' />
                Voltar
              </M.ButtonBack>
              <a id='logoKabum' href='/' aria-label='Página inicial KaBuM!'>
                {MobileLogo}
              </a>
            </M.CleanSection>
          </>
        )}

        {!clean && (
          <>
            <M.ContentSection>
              <M.LeftItemContent>
                <NavDrawer headerElementPositions={headerElement} />
                {isLoggedIn && (
                  <div
                    className='ml-6'
                    onClick={() =>
                      (window.location.href = '/minha-conta/notificacoes')
                    }
                    onKeyUp={() =>
                      (window.location.href = '/minha-conta/notificacoes')
                    }
                    data-testid='icon-notification'
                  >
                    <div className='relative'>
                      <IconNotification width={24} height={24} color='#fff' />
                      {noticeQuantity !== '0' && (
                        <span className='absolute px-4 bg-secondary-500 rounded-full -top-8 left-12 text-xs text-white-500'>
                          {noticeQuantity}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </M.LeftItemContent>
              <M.CenterItemContent
                as='a'
                id='logoKabum'
                href='/'
                aria-label='Página inicial KaBuM!'
              >
                {MobileLogo}
              </M.CenterItemContent>
              <M.RightItemContent>
                {renderAccessibilityMenu}
                <S.Link
                  id='linkCarrinhoHeader'
                  title='Carrinho'
                  href='/carrinho'
                >
                  <Badge content={cartProductsQuantity} position='topRight'>
                    <IconHeaderCart size={24} color='#fff' />
                  </Badge>
                </S.Link>
              </M.RightItemContent>
            </M.ContentSection>
            <M.SearchbarSection id='barraBuscaKabum'>
              <Searchbar inputSearchId='input-busca' />
            </M.SearchbarSection>
          </>
        )}
        {isLoggedIn && addresses?.length > 0 && <SelectAddress />}
      </M.ContainerWrapper>
    </S.Container>
  )
}

export default Mobile
