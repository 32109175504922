import { IconCloseBanner } from '@k_frontend/icons'
import { TopBannerProps } from './TopBanner.types'

export function TopBanner({
  bannerRef,
  banner,
  onCloseBanner,
  onBannerClick,
  hasImgAndIsOpen,
  isInternalUrlBanner
}: Readonly<TopBannerProps>) {
  return (
    <div className='relative flex items-center'>
      {hasImgAndIsOpen ? (
        <div
          className='flex justify-center items-center w-full h-48 desktop:h-64'
          ref={bannerRef}
          onClick={() => onBannerClick(banner?.clickUrl)}
          onKeyDown={() => onBannerClick(banner?.clickUrl)}
        >
          <a
            className='w-full'
            href={banner?.destinationUrl}
            target={isInternalUrlBanner ? '_self' : '_blank'}
          >
            <img
              className='w-full h-48 object-cover desktop:h-64'
              src={banner?.mediaUrl}
              alt={banner?.alt}
            />
          </a>
          <button
            type='button'
            className='absolute right-2 p-16 flex items-center cursor-pointer'
            onClick={onCloseBanner}
            aria-label='Fechar banner'
          >
            <IconCloseBanner className='w-12 h-12' aria-hidden />
          </button>
        </div>
      ) : null}
    </div>
  )
}
