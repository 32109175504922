import { IconDefaultProfileLogo } from '@k_frontend/icons'

import { useHeaderContext } from '../Header.context'
import * as S from './ProfileAvatar.styles'

export default function ProfileAvatar() {
  const { kernel, isPrime, profileImage, isLoggedIn } = useHeaderContext()

  return (
    <S.Container id='blocoAvatarUsuario' isPrime={isPrime}>
      {isLoggedIn ? (
        profileImage && profileImage !== '' ? (
          <S.Avatar
            src={profileImage}
            loading='lazy'
            alt='profileLogo'
            width={32}
            height={32}
          />
        ) : (
          <img
            src={`${kernel.STATIC_KABUM_IMAGES_K5}/profile_ninja.png`}
            alt='Ninja'
            width={32}
            height={32}
          />
        )
      ) : (
        <IconDefaultProfileLogo />
      )}
    </S.Container>
  )
}
