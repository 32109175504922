import { UpdateOfferModel } from 'domain/models'
import { OffersList, OffersListParams } from 'services/offersList'

export type OfferStateParams = {
  campaign: OffersListParams['campaign']
  page: OffersListParams['page']
  limit: OffersListParams['limit']
  brands?: string[]
  order?: OffersListParams['order']
  evaluation?: OffersListParams['evaluation']
  minDiscount?: OffersListParams['minDiscount']
  maxDiscount?: OffersListParams['maxDiscount']
  minPrice?: OffersListParams['minPrice']
  maxPrice?: OffersListParams['maxPrice']
  dep?: OffersListParams['dep']
  soldByKabum?: OffersListParams['soldByKabum']
  sec?: OffersListParams['sec']
  string?: OffersListParams['string']
  type?: OffersListParams['type']
  stamps?: OffersListParams['stamps']
  freeShipping?: OffersListParams['freeShipping']
  flashShipping?: OffersListParams['flashShipping']
  isUserPrime?: boolean
}

type InitialState = {
  offer?: OffersList
  params: OfferStateParams
}

export enum FilterPressEnum {
  DEFAULT = 'default',
  PAGINATION = 'pagination',
  BACK = 'back'
}

export type OfferState = {
  filterPressed: string
  loading: boolean
  offer: OffersList
  showingRemainingFilters: Record<string, boolean>
  params: OfferStateParams
  stockUpdate: UpdateOfferModel.Model
  init: (initialState: InitialState) => void
  setLoading: (loading: boolean) => void
  setStockUpdate: (stockUpdate: UpdateOfferModel.Model) => void
  removeOfferList: (offerCode: number) => void
  setParams: (
    params: OfferStateParams,
    filterPressed?: string,
    page?: number
  ) => void
  setOffer: (offer: OffersList) => void
  setShowingRemainingFilters: (
    showingRemainingFilters: Record<string, boolean>
  ) => void
  clearFilters: (campaign: string, limit?: number) => void
}
