import { useEffect, useRef, useState } from 'react'

import {
  NewtailBannerData,
  dataLayerEventPromotionClick,
  dataLayerEventPromotionView,
  formatBannerData,
  getBannersByMenu,
  getCategories,
  sendBeaconData,
  useUser
} from '@k_frontend/core'
import { useBannerMonetizaDepartments } from 'hooks/useBannerMonetiza'
import { useVisibility } from 'hooks/useVisibility'

import { ResizeHOC } from 'components/ResizeHOC'
import { useHeaderContext } from '../Header.context'
import { DepartmentsMenuProps } from './DepartmentsMenu.types'
import DepartmentsMenuDesktop from './Desktop'
import DepartmentsMenuMobile from './Mobile'

const INITIAL_DEPARTMENT = { name: '', path: '', id: null }

export default function DepartmentsMenu({
  onCloseMenu,
  kernel
}: DepartmentsMenuProps) {
  const bannerRef = useRef(null)
  const isVisible = useVisibility(bannerRef, { threshold: 0.5 })

  const { session, clientId } = useUser()
  const { cookieIsMobile } = useHeaderContext()

  const [categories, setCategories] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState(INITIAL_DEPARTMENT)
  const [currentSection, setCurrentSection] = useState(INITIAL_DEPARTMENT)
  const [departments, setDepartments] = useState([])
  const [sections, setSections] = useState([])
  const [menuTimeout, setMenuTimeout] = useState<any>()
  const [currentMenuId, setCurrentMenuId] = useState(0)
  const [cachedMenus, setCachedMenus] = useState([])

  const menuId = currentSection?.id ?? currentDepartment?.id

  const departmentName = departments?.length > 0 && currentDepartment.name

  const sectionName = sections?.length > 0 && currentSection.name

  const { data: bannerData, isLoading } = useBannerMonetizaDepartments({
    placementType: 'departamentos',
    departmentName,
    sectionName,
    menuId
  })

  const beaconData = {
    user_id: clientId,
    session_id: session
  }

  function setBannerSessionStorage(
    key: string,
    bannerData: NewtailBannerData,
    ttl: number
  ) {
    const now = new Date()
    const item = {
      value: bannerData,
      expiry: now.getTime() + ttl
    }
    sessionStorage.setItem(key, JSON.stringify(item))
  }

  const banner =
    (bannerData?.departamentos && bannerData?.departamentos[0]) ?? null

  const payloadFormatted = formatBannerData({
    name: banner?.title || banner?.campaignName,
    index: 1,
    position: 'tema_departamentos_1',
    creative: banner?.creative,
    bannerId: banner?.bannerId
  })

  async function loadDepartments() {
    try {
      const departments = await getCategories('', kernel)
      setDepartments(departments?.list)
    } catch (e) {
      console.error(JSON.stringify(e))
    }
  }

  async function handleEnterDepartment(slug: string, name: string) {
    const menuTimeout = setTimeout(async () => {
      const menuCached = cachedMenus.find((e) => e.selector === slug)
      let menuId
      if (menuCached) {
        const { list, id } = menuCached
        menuId = id
        setSections(list)
      } else {
        const response = await getCategories(slug, kernel)
        const { list, code } = response
        const sortedList = sortMenu(list)
        menuId = code
        setCachedMenus([
          ...cachedMenus,
          { list: sortedList, id: menuId, selector: slug }
        ])
        setSections(sortedList)
      }
      setCurrentDepartment({ name, id: menuId, path: slug })
      setCategories([])
      setCurrentMenuId(menuId)
    }, 250)
    setMenuTimeout(menuTimeout)
  }

  async function handleEnterSection(slug: string, name: string) {
    const path = `${currentDepartment.path}/${slug}`
    const menuTimeout = setTimeout(async () => {
      let menuId
      const menuCached = cachedMenus.find((e) => e.selector === path)
      if (menuCached) {
        const { list, id } = menuCached
        menuId = id
        setCategories(list)
      } else {
        const response = await getCategories(path, kernel)
        const { code, list } = response
        const sortedList = sortMenu(list)
        menuId = code
        setCachedMenus([
          ...cachedMenus,
          { list: sortedList, id: menuId, selector: path }
        ])
        setCategories(sortedList)
      }
      setCurrentSection({ name, id: menuId, path })
      setCurrentMenuId(menuId)
    }, 250)
    setMenuTimeout(menuTimeout)
  }

  function sortMenu(categoriesToSort) {
    return categoriesToSort.sort((a, b) => {
      return a.name.trim().localeCompare(b.name.trim(), undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    })
  }

  function handleBackMenu() {
    const hasSections = sections.length > 0
    const hasCategories = categories.length > 0

    if (hasCategories) {
      setCategories([])
      setCurrentMenuId(currentDepartment.id)
    } else if (hasSections) {
      setSections([])
      setCurrentMenuId(null)
    } else onCloseMenu()
  }

  const dataLayerBannerClick = () => {
    dataLayerEventPromotionClick(payloadFormatted)
    if (banner?.clickUrl) {
      sendBeaconData(banner?.clickUrl, beaconData)
    }
  }

  useEffect(() => {
    loadDepartments()
  }, [])

  useEffect(() => {
    if (bannerData) {
      setBannerSessionStorage(`menu=${menuId}`, bannerData, 1000 * 60 * 10)
    }
  }, [bannerData, menuId])

  useEffect(() => {
    if (banner) {
      dataLayerEventPromotionView(payloadFormatted)
      sendBeaconData(banner?.impressionUrl, beaconData)
    }
    if (banner && isVisible) {
      sendBeaconData(banner?.viewUrl, beaconData)
    }
  }, [banner, isVisible])

  return (
    <ResizeHOC
      menuTimeout={menuTimeout}
      departments={departments}
      sections={sections}
      categories={categories}
      currentDepartment={currentDepartment}
      currentSection={currentSection}
      currentMenuId={currentMenuId}
      MobileComponent={DepartmentsMenuMobile}
      DesktopComponent={DepartmentsMenuDesktop}
      isMobileScreen={cookieIsMobile}
      kernel={kernel}
      bannerData={bannerData}
      isLoading={isLoading}
      handleEnterDepartment={handleEnterDepartment}
      handleEnterSection={handleEnterSection}
      handleBackMenu={handleBackMenu}
      handleDisableMenu={onCloseMenu}
      dataLayerBannerClick={dataLayerBannerClick}
      hrefPrefix={kernel.KABUM_URL}
      getBannersByMenu={(menu: number) => getBannersByMenu(kernel, menu)}
      bannerRef={bannerRef}
    />
  )
}
