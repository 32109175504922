import { useState } from 'react'

import { ResizeHOC } from 'components/ResizeHOC'
import { useHeaderContext } from '../Header.context'
import { PropsResizeAddress } from './SelectAddress.type'
import SelectAddressDesktop from './SelectAddressDesktop'
import SelectAddressMobile from './SelectAddressMobile'

export default function SelectAddress() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { currentAddress, cookieIsMobile, isLoggedIn, addresses } =
    useHeaderContext()

  if (!isLoggedIn || !addresses?.length) return null

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const addressSelect = `${currentAddress?.street}, ${currentAddress?.number} - ${currentAddress?.city}/${currentAddress?.state}`

  return (
    <ResizeHOC<PropsResizeAddress>
      DesktopComponent={SelectAddressDesktop}
      MobileComponent={SelectAddressMobile}
      handleCloseModal={handleCloseModal}
      handleOpenModal={handleOpenModal}
      isOpenModal={isOpenModal}
      addressSelect={addressSelect}
      isMobileScreen={cookieIsMobile}
    />
  )
}
