import { useEffect, useLayoutEffect, useState } from 'react'
import { tailwindTheme } from 'theme/tailwindTheme'
import { ResizeHOCProps } from './ResizeHOC.types'

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

export function ResizeHOC<T>({
  MobileComponent,
  DesktopComponent,
  minDesktopWidth = tailwindTheme.screens.desktop,
  isMobileScreen,
  ...props
}: ResizeHOCProps<T>) {
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(
    !isMobileScreen
  )

  function handleMedia(event: MediaQueryListEvent) {
    setIsDesktop(event.matches)
  }

  useIsomorphicLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(`(min-width: ${minDesktopWidth})`)
      setIsDesktop(mediaQuery.matches)

      mediaQuery.addEventListener('change', handleMedia)
      return () => mediaQuery.removeEventListener('change', handleMedia)
    }
  }, [minDesktopWidth])

  if (isDesktop === undefined) return null

  return (
    <>
      {isDesktop ? (
        <DesktopComponent {...props} />
      ) : (
        <MobileComponent {...props} />
      )}
    </>
  )
}
