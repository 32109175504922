import { IconDefaultProfileLogo } from '@k_frontend/icons'

export default function ProfileAvatarLoading({ isPrime = false }) {
  return (
    <figure
      id='blocoAvatarUsuario'
      className={`flex justify-center items-center rounded-[50%] border-[3px] border-solid  h-[36px] w-[36px] ${isPrime ? 'border-black-800' : 'border-[#347bbe]'}`}
    >
      <IconDefaultProfileLogo />
    </figure>
  )
}
