import { HeaderContainer } from 'containers/HeaderContainer'

import { ResizeHOC } from 'components/ResizeHOC'
import { HeaderContextProvider } from './Header.context'
import { HeaderControllerProps as Props } from './Header.types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export function Header(props: Props) {
  const isDynamicProductPage = props.pathname.match(/^\/produto\/(.+)/)
  const showFloatingHeader = !isDynamicProductPage

  return (
    <HeaderContextProvider {...props}>
      <ResizeHOC
        MobileComponent={HeaderMobile}
        DesktopComponent={HeaderDesktop}
        isMobileScreen={props.cookieIsMobile}
      />
      {showFloatingHeader && <HeaderContainer />}
    </HeaderContextProvider>
  )
}
