import { getCookie } from 'cookies-next'
import CookiesInterface from 'interfaces/cookies'
import Kernel, { KABUM_COOKIE_URL } from 'settings/kernel'

export const addProductToCookies = (product) => {
  product.id_vendedor = product.id_vendedor || 0
  product.id_origem = product.id_origem || 1

  const CookieInstance = new CookiesInterface()
  const sellers = sellersStringToObj(CookieInstance.getSellers())
  const exists =
    sellers[product.id_vendedor] &&
    sellers[product.id_vendedor][product.id_origem] &&
    sellers[product.id_vendedor][product.id_origem].products.find((it) => {
      if (product.mercadoria_codigo || it.tagCode || it.tag_code) {
        return (
          parseInt(it.code) === parseInt(product.codigo) &&
          (parseInt(product.mercadoria_codigo) === parseInt(it.tagCode) ||
            parseInt(product.mercadoria_codigo) === parseInt(it.tag_code))
        )
      }

      return parseInt(it.code) === parseInt(product.codigo)
    })
  if (exists) return

  if (!sellers[product.id_vendedor]) {
    sellers[product.id_vendedor] = {}
    sellers[product.id_vendedor][product.id_origem] = {
      id: product.id_origem,
      shippingDate: '',
      shippingOptionCode: '',
      shippingPeriod: '',
      shippingName: ''
    }
    sellers[product.id_vendedor][product.id_origem].products = []
  } else if (!sellers[product.id_vendedor][product.id_origem]) {
    sellers[product.id_vendedor][product.id_origem] = {
      id: product.id_origem,
      shippingDate: '',
      shippingOptionCode: '',
      shippingPeriod: '',
      shippingName: ''
    }
    sellers[product.id_vendedor][product.id_origem].products = []
  }

  sellers[product.id_vendedor][product.id_origem].products.push({
    id: product.id,
    code: product.codigo,
    quantity: product.quantidade,
    offerId: product.id_marketplace,
    tagCode: product.mercadoria_codigo
  })

  CookieInstance._setCookie(
    'sellers',
    sellersArrayToString(sellersObjToArray(sellers))
  )
}

export const getCartRequestData = () => {
  const CookieInstance = new CookiesInterface()
  const cartDataString = CookieInstance.getSellers()
  const client_id = CookieInstance.getClientId()
  const session = CookieInstance.getSession()
  const coupon = CookieInstance.getCoupon() || ''
  const media_origin = CookieInstance.getMediaOrigin()
  const sellers = sellersObjToArray(sellersStringToObj(cartDataString))

  return {
    store: Kernel.STORE_CODE,
    client_id,
    session,
    coupon,
    sellers,
    media_origin
  }
}

export const getServicesForCartRequestData = () => {
  const services = new CookiesInterface().getServices()

  if (!services) {
    return []
  }

  return services.split('#').map((service) => {
    const [sellerId, originId, productCode, name, code] = service.split('|')

    return {
      sellerId: sellerId,
      originId: originId,
      productCode: productCode,
      name,
      code: parseInt(code)
    }
  })
}

const sellersObjToArray = (sellers) => {
  return Object.keys(sellers).map((sellerId) => ({
    id: sellerId,
    origins: Object.values(sellers[sellerId])
  }))
}

export const servicesArrayToString = (servicesArray) => {
  return servicesArray
    .map(
      (service) =>
        `${service.sellerId}|${service.originId}|${service.productCode}|${service.name}|${service.code}`
    )
    .join('#')
}

function compareService(service, compare) {
  return (
    String(service.sellerId) === String(compare.sellerId) &&
    String(service.originId) === String(compare.originId) &&
    String(service.productCode) === String(compare.productCode) &&
    service.name === compare.name
  )
}

function updateServiceInArray(existingServicesArray, currentService) {
  if (!currentService) return

  const serviceIndex = existingServicesArray.findIndex((service) =>
    compareService(service, currentService)
  )

  if (serviceIndex !== -1) {
    if (currentService.code !== 0) {
      existingServicesArray[serviceIndex].code = currentService.code
    } else {
      existingServicesArray.splice(serviceIndex, 1)
    }
  } else if (currentService.code !== 0) {
    existingServicesArray.push(currentService)
  }
}

export function clearServicesFromCookie(productCode) {
  const existingServicesArray = getServicesForCartRequestData()

  const filteredServicesArray = existingServicesArray.filter(
    (service) => String(service.productCode) !== String(productCode)
  )

  new CookiesInterface().setServices(
    servicesArrayToString(filteredServicesArray)
  )
}

export function handleCookie(currentService) {
  const existingServicesArray = getServicesForCartRequestData()

  if (existingServicesArray.length > 0) {
    updateServiceInArray(existingServicesArray, currentService)
  } else {
    if (currentService.code !== 0) {
      existingServicesArray.push(currentService)
    }
  }

  new CookiesInterface().setServices(
    servicesArrayToString(existingServicesArray)
  )
}

export const sellersArrayToString = (sellersArray) => {
  let id = '0000000000'
  let sellerId = '0'
  let originId = '0'
  let shippingOption = '0'
  let shippingDate = ''
  let shippingPeriod = ''
  let shippingName = ''
  let productCode = '0'
  let offerId = '0'
  let tagCode = '0'
  let quantity = '0'

  const returnString = []

  sellersArray.forEach((seller) => {
    sellerId = seller.id || '0'

    seller.origins.forEach((origin) => {
      originId = origin.id || '0'
      shippingOption = origin.shipping_code || '0'
      shippingDate = origin.shipping_date || ''
      shippingPeriod = origin.shipping_period || ''
      shippingName = origin.shipping_name || ''
      origin.products.forEach((product) => {
        id = product.id || '0'
        productCode = product.code || '0'
        offerId = product.offerId || product.offer_id || ''
        tagCode = product.tagCode || product.tag_code || ''
        quantity = product.quantity || '0'

        returnString.push(
          `${sellerId}|${originId}|${shippingOption}|${shippingDate}|${shippingPeriod}|${shippingName}|${id}|${productCode}|${quantity}|${offerId}|${tagCode}`
        )
      })
    })
  })

  return returnString.join('#')
}

const sellersStringToObj = (sellersString) => {
  const services = getServicesForCartRequestData()

  if (!sellersString) {
    return {}
  }

  const tmp = {}
  const stringList = sellersString.split('#')

  if (stringList.length === 0) {
    return {}
  }

  stringList.forEach((sellerString) => {
    const [
      sellerId,
      originId,
      shipping_code,
      shipping_date,
      shipping_period,
      shipping_name,
      id,
      productCode,
      quantity,
      offer_id,
      tag_code
    ] = sellerString.split('|')

    if (!tmp[sellerId]) {
      tmp[sellerId] = {}
    }

    if (!tmp[sellerId][originId]) {
      tmp[sellerId][originId] = {
        id: originId,
        shipping_code,
        shipping_date,
        shipping_period,
        shipping_name,
        products: []
      }
    }

    const serviceForProduct = services.filter(
      (service) =>
        service.sellerId === parseInt(sellerId) &&
        service.originId === parseInt(originId) &&
        service.productCode === parseInt(productCode)
    )

    tmp[sellerId][originId].products.push({
      code: productCode,
      id,
      seller_id: sellerId,
      quantity,
      tag_code,
      offer_id,
      services: serviceForProduct
        ? serviceForProduct.map((service) => service.code)
        : []
    })
  })

  return tmp
}

export const getProductsInCookies = (sellersArray) => {
  return sellersArray.sellers.flatMap((seller) =>
    seller.origins.flatMap((origin) =>
      origin.products.map((product) => ({
        code: product.code,
        id: product.id,
        seller_id: seller.id,
        quantity: product.quantity,
        offer_id: product.offer_id,
        services: product.services || [],
        tag_code: product.tag_code
      }))
    )
  )
}

export function stringifyRemovedAssemblies(products) {
  return products
    .map(
      (product) =>
        `${product.sellerId}|${product.originId}|${product.productCode}`
    )
    .join('#')
}

export function stringifyProductIdentifier(product) {
  return `${product.sellerId}|${product.originId}|${product.productCode}`
}

export function parseStringifiedProductIdentifier(product) {
  const [sellerId, originId, productCode] = product.split('|')
  return {
    sellerId,
    originId,
    productCode
  }
}

export function pushToStringifiedContent(stringifiedContent, contentToPush) {
  if (!stringifiedContent || stringifiedContent.includes(contentToPush))
    return contentToPush

  return `${stringifiedContent}#${contentToPush}`
}

export function removeFromStringifiedContent(
  stringifiedContent,
  contentToRemove
) {
  if (!stringifiedContent) return ''

  return stringifiedContent.replace(contentToRemove, '').replace(/(^#|#$)/, '')
}

export function parseStringfiedRemovedAssemblies() {
  const removedAssemblies = getCookie('removedAssemblies', {
    domain: KABUM_COOKIE_URL
  })

  if (!removedAssemblies) return []

  return removedAssemblies.split('#').map(parseStringifiedProductIdentifier)
}
