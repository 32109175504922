export default function ProfileInfoLoading() {
  return (
    <div
      id='blocoInformacoesCliente'
      className='flex flex-col justify-center items-center grow leading-4 ml-[6px] max-w-[130px] gap-4'
    >
      <div className='h-12 w-[85%] animate-pulse rounded-2 bg-black-500' />
      <div className='h-12 w-[85%] animate-pulse rounded-2 bg-black-500' />
    </div>
  )
}
