import { useEffect } from 'react'
import Notifications, { notify } from 'react-notify-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import {
  handtalkInstance,
  useAddresses,
  useHandtalk,
  useUser,
  useVisitor,
  useWishlist
} from '@k_frontend/core'
import '@kbm/icons/css'
import { Poppins } from '@next/font/google'
import LayoutController from 'components/Layout'
import { useCart } from 'hooks/useCart'
import Cookies from 'interfaces/cookies'
import Kernel from 'settings/kernel'
import * as SEO from 'settings/seo'
import wrapper from 'state/store'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'
import { getCostumer } from 'utils/getCostumer'
import { handleFlagBlackFriday } from 'utils/handleFlagBlackFriday'
import { handleCacheStore } from 'utils/handleStore'
import {
  getMiniCartFeatureFlag,
  setMiniCartFeatureFlag
} from 'utils/miniCartFeatureFlag'
import { getSearchVariation, setSearchVariation } from 'utils/searchVariation'
import workWithUs from 'utils/workWithUs'
import { shallow } from 'zustand/shallow'

import { openCSATWidget } from '../utils/CSAT'
import { GlobalStyle } from './_appStyles'
import './globals.css'
import '@kbm/icons/css'
import ddRum from 'utils/ddRum'

const poppins = Poppins({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '500', '600', '700', '800']
})

const useUserSelector = (state) => ({
  clientId: state.clientId,
  session: state.session,
  init: state.init,
  flagBlackFriday: state.flagBlackFriday,
  setFlagBlackFriday: state.setFlagBlackFriday,
  validateSession: state.validateSession,
  validSession: state.validSession,
  isPrime: state.isPrime
})

const useVisitorSelector = (state) => ({
  init: state.init,
  push: state.pushToDataLayer
})

const useAddressSelector = (state) => ({
  init: state.init
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
})

const noIndexRoutes = [
  '/vitrine-marca/',
  '/vitrine-lojas/',
  '/vitrine/',
  '/indique/',
  '/cartao_solicitacao'
]

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  const isHandtalkActive = useHandtalk((state) => state.isHandtalkActive)
  const getProductsCart = useCart((state) => state.getProductsCart)
  const { cookieIsMobile, hasTopBannerClosed } = pageProps
  const {
    clientId,
    session,
    init: initUser,
    setFlagBlackFriday,
    validateSession,
    validSession,
    isPrime
  } = useUser(useUserSelector, shallow)
  const { push: pushVisitor } = useVisitor(useVisitorSelector, shallow)

  const { init: initAddresses } = useAddresses(useAddressSelector, shallow)

  const { getWishlist } = useWishlist()

  async function initReviewAttendance() {
    await window.Genesys(
      'subscribe',
      'MessagingService.conversationDisconnected',
      function ({ data }) {
        if (
          data?.readOnly === true &&
          data?.message?.originatingEntity !== 'Bot'
        ) {
          try {
            openCSATWidget(data.message.id)
          } catch {
            return
          }
        }
        return ''
      }
    )
  }

  function handleLoadGenesys() {
    window.sendClientIdToChat(clientId)
    initReviewAttendance()
  }

  useEffect(() => {
    if (session && !validSession) {
      notify.show('Sua sessão expirou. Faça login novamente.', 'error')
    }
    const searchVariation = getSearchVariation()
    const miniCartFeatureFlag = getMiniCartFeatureFlag()
    if (session) {
      if (!searchVariation) {
        setSearchVariation(session)
      }

      if (miniCartFeatureFlag === undefined) {
        setMiniCartFeatureFlag(session)
      }
    }
  }, [validSession, session])

  useEffect(() => {
    ddRum()
    validateSession(Kernel)
    initUser(Kernel)
    handleFlagBlackFriday(setFlagBlackFriday)
    if (clientId && session) initAddresses(Kernel, clientId, session)
    pushVisitor()
    workWithUs()
    getProductsCart()
    getWishlist(clientId, session, `${Kernel.BACKEND_CUSTOMER_WISHLIST}`)
  }, [])

  const customerId = getCostumer()
  const device = new Cookies().getIsMobileDeviceType()
  const shKey = Kernel.SH_KEY

  return (
    <>
      <Head>
        {noIndexRoutes.some((route) => router.asPath.startsWith(route)) && (
          <meta name='robots' content='noindex, nofollow' />
        )}
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>{SEO.SITE_TITLE}</title>
        {isPrime && (
          <>
            <meta name='theme-color' content={theme.colors.black(900)} />
            <meta
              name='apple-mobile-web-app-status-bar-style'
              content={theme.colors.black(900)}
            />
          </>
        )}

        <script
          defer
          dangerouslySetInnerHTML={{
            __html: `
              var SmartHint_AnonymousConsumer = '${customerId}';
              var SmartHint_ClickClass = 'productLink';
              var SmartHint_Channel = '${device}';
              var smarthintkey = '${shKey}';
            `
          }}
        />
      </Head>

      <Script
        src={`${Kernel.STATIC_KABUM}/conteudo/genesys/genesys.script.js`}
        type='application/javascript'
        id='genesysChat'
        data-chat-key={Kernel.DEPLOYMENT_ID_GENESYS}
        onLoad={() => handleLoadGenesys()}
        strategy='lazyOnload'
      />
      <Script
        type='application/javascript'
        src='https://survey.solucx.com.br/widget.js'
        strategy='lazyOnload'
      />

      <GlobalStyle />

      <Script
        strategy='afterInteractive'
        type='application/javascript'
        src='https://static.smarthint.co/Scripts/i/SmartHintTrack.min.js'
      />

      {isHandtalkActive && (
        <Script
          strategy='afterInteractive'
          type='application/javascript'
          src='https://plugin.handtalk.me/web/latest/handtalk.min.js'
          onLoad={() => handtalkInstance()}
        />
      )}

      <Script
        id='trackSmartHint'
        strategy='lazyOnload'
        type='application/javascript'
      >
        {`window.SmartHint?.Track.Initialize()`}
      </Script>
      <div id='font-container' className={poppins.className}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <LayoutController
              cookieIsMobile={cookieIsMobile}
              cookieIsPrime={isPrime}
              hasTopBannerClosed={hasTopBannerClosed}
            >
              <Component {...pageProps} />
              <Notifications options={{ zIndex: 9999999 }} />
            </LayoutController>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    </>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const {
    ctx: { req, res }
  } = appContext
  const { cookies } = req || {}

  handleCacheStore(req, res)

  appProps.pageProps = {
    ...appProps.pageProps,
    cookieIsMobile: cookies?.isMobile === 'true',
    hasTopBannerClosed: cookies?.topBannerClosed === 'true'
  }
  useUser.setState({
    isPrime: cookies?.CP_1286400 === 'true'
  })
  return { ...appProps }
}

export default wrapper.withRedux(MyApp)
