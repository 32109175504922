import dynamic from 'next/dynamic'

import {
  IconHeaderFavorites,
  IconHeaderSupport,
  IconNotification
} from '@k_frontend/icons'
import { Badge, Col, Container, Row } from '@k_frontend/ui'

import AccessibilityMenuLoading from 'components/AccessibilityMenu/AccessibilityMenu.loading'
import HandtalkToggleLoading from 'components/HandtalkToggle/HandtalkToggle.loading'
import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import {
  NotificationsContainer,
  useNotificationsStore
} from 'containers/NotificationsContainer'
import { useMemo } from 'react'
import CartButton from '../CartButton/CartButton'
import { useHeaderContext } from '../Header.context'
import * as S from '../Header.styles'
import Menu from '../Menu'
import NavDrawer from '../NavDrawer'
import ProfileAvatarLoading from '../ProfileAvatar/ProfileAvatar.loading'
import ProfileInfoLoading from '../ProfileInfo/ProfileInfo.loading'
import Searchbar from '../Searchbar'
import SelectAddress from '../SelectAddress'
import * as D from './HeaderDesktop.styles'

const ProfileInfo = dynamic(() => import('../ProfileInfo'), {
  loading: () => <ProfileInfoLoading />,
  ssr: false
})

const ProfileAvatarDynamic = (isPrime = false) =>
  dynamic(() => import('../ProfileAvatar'), {
    loading: () => <ProfileAvatarLoading isPrime={isPrime} />,
    ssr: false
  })

const AccessibilityMenu = dynamic(
  () =>
    import('components/AccessibilityMenu').then(
      (module) => module.AccessibilityMenu
    ),
  {
    loading: () => <AccessibilityMenuLoading />,
    ssr: false
  }
)
const HandtalkToggle = dynamic(
  () =>
    import('components/HandtalkToggle').then((module) => module.HandtalkToggle),
  {
    loading: () => <HandtalkToggleLoading />,
    ssr: false
  }
)

const HeaderDesktop = () => {
  const {
    isPrime,
    clean,
    isLoggedIn,
    addresses,
    wishlist,
    isMounted,
    DesktopLogo
  } = useHeaderContext()
  const ProfileAvatar = ProfileAvatarDynamic(isPrime)

  const { setIsOpenNotifications, isOpenNotifications, noticeQuantity } =
    useNotificationsStore()
  const { isViewFloatingMenu } = useHeaderContainer()

  const renderAccessibilityMenu = useMemo(() => {
    if (!isViewFloatingMenu) {
      return <AccessibilityMenu />
    }
  }, [isViewFloatingMenu])

  return (
    <D.Container clean={clean}>
      {isOpenNotifications && <NotificationsContainer />}
      <D.ContainerWrapper
        isPrime={isPrime}
        key={String(isMounted)}
        showAddresses={isLoggedIn && addresses?.length > 0}
      >
        <Container>
          <Row>
            <Col lg={8} xl={8} align='center'>
              {!clean && <NavDrawer />}
              <S.LogoSection id='logoKabum'>
                <D.ContainerLogo href='/' aria-label='Página inicial KaBuM!'>
                  {DesktopLogo}
                </D.ContainerLogo>
              </S.LogoSection>

              {!clean && (
                <D.SearchbarSection
                  id='barraBuscaKabum'
                  showAddresses={isLoggedIn && addresses?.length > 0}
                >
                  <Searchbar inputSearchId='input-busca' />

                  {isLoggedIn && addresses?.length > 0 && <SelectAddress />}
                </D.SearchbarSection>
              )}
            </Col>
            <Col lg={4} xl={4} align='center' justify='space-between'>
              <D.UserInfoContainer>
                <ProfileAvatar />
                <ProfileInfo />
              </D.UserInfoContainer>
              {clean && (
                <D.HandtalkWrapper>
                  <HandtalkToggle />
                </D.HandtalkWrapper>
              )}

              {!clean && (
                <S.IconsContainer>
                  {renderAccessibilityMenu}
                  <S.Link id='linkSacHeader' title='SAC' href='/faq'>
                    <IconHeaderSupport size={24} />
                  </S.Link>
                  <S.Link
                    id='linkFavoritosHeader'
                    title='Favoritos'
                    href='/minha-conta/favoritos'
                  >
                    <Badge
                      content={!!wishlist && wishlist.total_wishes}
                      position='topRight'
                    >
                      <IconHeaderFavorites size={20} />
                    </Badge>
                  </S.Link>
                  {isLoggedIn && (
                    <div
                      className='flex items-center justify-center w-[36px] h-[36px]'
                      onClick={() =>
                        setIsOpenNotifications(!isOpenNotifications)
                      }
                      onKeyUp={() =>
                        setIsOpenNotifications(!isOpenNotifications)
                      }
                      data-testid='icon-notification'
                    >
                      <div className='relative cursor-pointer'>
                        <IconNotification
                          width={24}
                          height={24}
                          color='#ffffffcc'
                        />
                        {noticeQuantity !== '0' && (
                          <span className='flex items-center font-bold absolute h-20 px-6 bg-secondary-600 rounded-full -top-[10px] left-12 text-xs text-black-300'>
                            {noticeQuantity}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <CartButton />
                </S.IconsContainer>
              )}
            </Col>
          </Row>
        </Container>
      </D.ContainerWrapper>

      {!clean && <Menu />}
    </D.Container>
  )
}

export default HeaderDesktop
