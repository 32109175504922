import { useEffect } from 'react'

import { HandtalkState, useHandtalk } from '@k_frontend/core'
import {
  IconCategories,
  IconCloseModal,
  IconLibras,
  IconSandwich,
  IconSandwichRounded
} from '@k_frontend/icons'
import { Button } from '@k_frontend/ui'
import themeDefault from 'theme'

import { ResizeHOC } from 'components/ResizeHOC'
import DepartmentsMenu from '../DepartmentsMenu'
import { useHeaderContext } from '../Header.context'
import ProfileAvatar from '../ProfileAvatar'
import * as S from './NavDrawer.styles'
import { NavDrawerProps } from './NavDrawer.types'

const useHandtalkSelector = (state: HandtalkState) => ({
  isHandtalkActive: state.isHandtalkActive,
  handleHandtalkToggle: state.handleHandtalkToggle,
  getLabel: state.getLabel
})

export default function NavDrawer({ headerElementPositions }: NavDrawerProps) {
  const {
    handleLogout,
    isLoggedIn,
    kernel,
    isPrime,
    clientName,
    cookieIsMobile,
    isDepartmentsActive,
    openDepartments,
    closeDepartments,
    isNavDrawerActive,
    openNavDrawer,
    closeNavDrawer,
    headerJson
  } = useHeaderContext()

  const { getLabel, handleHandtalkToggle, isHandtalkActive } =
    useHandtalk(useHandtalkSelector)

  function toggleNavDrawer() {
    if (isNavDrawerActive) {
      closeNavDrawer()
    } else {
      openNavDrawer()
    }
  }

  function redirectUri() {
    const currentUrl = window.location.href
    window.location.href = `/login?redirect_uri=${currentUrl}`
  }

  useEffect(() => {
    const html = document?.querySelector('html')
    const body = document?.querySelector('body')

    if (html?.style) {
      html.style.overflow = isNavDrawerActive ? 'hidden' : 'unset'
    }
    if (body?.style) {
      html.style.overflow = isNavDrawerActive ? 'hidden' : 'unset'
    }

    return () => {
      if (html?.style) {
        html.style.overflow = 'unset'
      }
      if (body?.style) {
        html.style.overflow = 'unset'
      }
    }
  }, [isNavDrawerActive])

  function getButtonIcon() {
    const desktop = {
      sandwich: <IconSandwich color={themeDefault.colors.white()} aria-hidden />
    }
    const mobile = {
      sandwich: <IconSandwichRounded color='#fff' aria-hidden />,
      close: (
        <IconCloseModal
          onClick={closeNavDrawer}
          color='#fff'
          width='24'
          height='24'
          data-testid='drawerButton'
          aria-hidden
        />
      )
    }

    return (
      <ResizeHOC
        DesktopComponent={() => <>{desktop.sandwich}</>}
        MobileComponent={() => (
          <>{mobile[isNavDrawerActive ? 'close' : 'sandwich']}</>
        )}
        isMobileScreen={cookieIsMobile}
      />
    )
  }

  return (
    <>
      <S.ButtonContainer
        id='menuHamburguer'
        onClick={toggleNavDrawer}
        data-testid='drawerButton'
        aria-label={isNavDrawerActive ? 'Fechar menu' : 'Abrir menu'}
        aria-expanded={isNavDrawerActive}
      >
        {getButtonIcon()}
      </S.ButtonContainer>
      {isNavDrawerActive && (
        <>
          <S.Background open={isNavDrawerActive} onClick={closeNavDrawer} />
          <S.NavDrawer
            open={isNavDrawerActive}
            isPrime={isPrime}
            id='menuLateral'
            data-testid='drawerMenu'
            bottomHeaderPosition={
              headerElementPositions?.bottom > 0
                ? headerElementPositions?.bottom
                : 0
            }
          >
            {isDepartmentsActive && (
              <S.CategoriesContent>
                <DepartmentsMenu
                  kernel={kernel}
                  onCloseMenu={closeDepartments}
                />
              </S.CategoriesContent>
            )}
            <S.CloseButton onClick={closeNavDrawer} aria-label='Fechar menu'>
              <IconCloseModal
                color={themeDefault.colors.white()}
                width='24'
                height='24'
              />
            </S.CloseButton>
            <S.Profile>
              <ProfileAvatar />
              <S.Label>
                Olá. {isLoggedIn ? clientName : 'Acesse sua conta'}
              </S.Label>
            </S.Profile>
            <S.Content isPrime={isPrime}>
              <S.Wrapper onlyMobile>
                <S.OpenDepartmentsItem isPrime={isPrime}>
                  <a onClick={() => openDepartments(true)}>
                    <IconCategories
                      width={16}
                      height={16}
                      color={themeDefault.colors.white()}
                    />
                    <span>Departamentos</span>
                  </a>
                </S.OpenDepartmentsItem>

                <S.Item>
                  <button
                    onClick={handleHandtalkToggle}
                    aria-pressed={isHandtalkActive}
                    aria-label={getLabel('full')}
                  >
                    <IconLibras
                      width={16}
                      height={16}
                      color={themeDefault.colors.white()}
                    />
                    <span>{getLabel('clean')}</span>
                  </button>
                </S.Item>

                {!!headerJson?.navDrawer?.a11y && (
                  <S.Item>
                    <a href={headerJson.navDrawer.a11y.link}>
                      <span
                        className='icons'
                        dangerouslySetInnerHTML={{
                          __html: headerJson.navDrawer.a11y.icon
                        }}
                      />
                      <span>{headerJson.navDrawer.a11y.label}</span>
                    </a>
                  </S.Item>
                )}

                <S.Separator isPrime={isPrime} />
              </S.Wrapper>
              <S.Wrapper>
                {headerJson?.navDrawer?.myAccountLink?.map(
                  (myAccountLink, index) => (
                    <div key={index}>
                      <S.Item key={index}>
                        <a href={myAccountLink.link}>
                          <span
                            className='icons'
                            dangerouslySetInnerHTML={{
                              __html: myAccountLink.icon
                            }}
                          />
                          <span>{myAccountLink.label}</span>
                        </a>
                      </S.Item>
                    </div>
                  )
                )}
                <S.Separator isPrime={isPrime} />
              </S.Wrapper>
              <S.Wrapper>
                {headerJson?.navDrawer?.generalLinks?.map((item, index) => (
                  <S.Item key={index}>
                    <a href={item.link}>
                      <span
                        className='icons'
                        dangerouslySetInnerHTML={{
                          __html: item.icon
                        }}
                      />
                      <span>{item.label}</span>
                    </a>
                  </S.Item>
                ))}
                <S.Separator isPrime={isPrime} />
              </S.Wrapper>
              <S.Wrapper>
                <ResizeHOC
                  DesktopComponent={() => (
                    <>
                      {headerJson?.navDrawer?.campaignLinks?.map(
                        (item, index) => (
                          <S.Item
                            key={index}
                            id={`${item.idPrefix}MenuLateral`}
                          >
                            <a href={item.link}>
                              <span
                                className='icons'
                                dangerouslySetInnerHTML={{
                                  __html: item.icon
                                }}
                              />
                              <span>{item.text}</span>
                            </a>
                          </S.Item>
                        )
                      )}
                      <S.Separator isPrime={isPrime} />
                    </>
                  )}
                  MobileComponent={() => (
                    <>
                      {headerJson?.navDrawer?.campaignLinks?.map(
                        (item, index) => (
                          <S.Item
                            key={index}
                            id={`${item.idPrefix}MenuLateral`}
                          >
                            <a href={item.link}>
                              <span
                                className='icons'
                                dangerouslySetInnerHTML={{
                                  __html: item.icon
                                }}
                              />
                              <span>{item.text}</span>
                            </a>
                          </S.Item>
                        )
                      )}
                      <S.Separator isPrime={isPrime} />
                    </>
                  )}
                  isMobileScreen={cookieIsMobile}
                />
              </S.Wrapper>
              <S.Wrapper>
                {headerJson?.navDrawer?.institutionalLinks?.map(
                  (item, index) => (
                    <S.Item key={index} id={`${item.idPrefix}MenuLateral`}>
                      <a href={item.url}>
                        <span
                          className='icons'
                          dangerouslySetInnerHTML={{
                            __html: item.icon
                          }}
                        />
                        <span>{item.text}</span>
                      </a>
                    </S.Item>
                  )
                )}
              </S.Wrapper>
            </S.Content>
            <S.Footer>
              {!isLoggedIn ? (
                <>
                  <Button onClick={() => redirectUri()} text='Entre' />

                  <a href={`${kernel.KABUM_URL}/cadastro`}>Cadastre-se</a>
                </>
              ) : (
                <Button onClick={() => handleLogout()} text='Sair' />
              )}
            </S.Footer>
          </S.NavDrawer>
        </>
      )}
    </>
  )
}
