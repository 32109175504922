import styled from 'styled-components'
import { tv } from 'tailwind-variants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  max-width: ${({ theme }) => theme.rem(130)};
  margin-left: ${({ theme }) => theme.rem(6)};
  position: relative;

  > span {
    ${({ theme }) => theme.font(12)}
    color: rgba(255, 255, 255, 0.8);
  }
`

export const ClientName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.font(12, 18, 700)}
  color: rgba(255, 255, 255, 0.8);
`

export const ProfileRow = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: ${({ theme }) => theme.colors.white(500)};
    margin: 0 ${({ theme }) => theme.rem(5)};
    ${({ theme }) => theme.font(12)}
  }
`

export const Logout = styled.button`
  ${({ theme }) => theme.font(12)}
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  cursor: pointer;
`

export const ProfileLinkBold = styled.a`
  ${({ theme }) => theme.font(12, 18, 700)}
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
`

export const ContainterSVG = tv({
  base: '[&_svg]:ml-6 [&_svg]:rotate-180',
  variants: {
    rotate: {
      true: '[&_svg]:rotate-0 [&_svg]:duration-500'
    }
  }
})
